export const CONTRACT_AGREEMENTS =
  'https://elbrusboot.camp/contract_agreements/'
export const TAXATION = {
  OOO: 'osn'
}

export const TERMINALKEY = {
  OOO: 1620223484819
}

export const CURRENCIES = {
  RUB: 'руб.'
}
