import { CONTRACT_AGREEMENTS, TAXATION, TERMINALKEY } from './constants'

const courses = {
  js: {
    msk: {
      ru: {
        price: 275000,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'Образовательные услуги по курсу FULL STACK JAVASCRIPT',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    spb: {
      ru: {
        price: 235000,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'Образовательные услуги по курсу FULL STACK JAVASCRIPT',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    kzn: {
      ru: {
        price: 210000,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'Образовательные услуги по курсу FULL STACK JAVASCRIPT',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    online: {
      ru: {
        price: 205000,
        docUrl: CONTRACT_AGREEMENTS,
        productName:
          'Образовательные услуги по курсу FULL STACK JAVASCRIPT онлайн',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    part_time_online: {
      ru: {
        price: 205000,
        docUrl: CONTRACT_AGREEMENTS,
        productName:
          'Образовательные услуги по курсу FULL STACK JAVASCRIPT онлайн',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    prepare: {
      ru: {
        price: 9900,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'JS PREPARE (RUS)',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    }
  },
  ds: {
    online: {
      ru: {
        price: 255000,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'Образовательные услуги по курсу  Data Science',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    },
    prepare: {
      ru: {
        price: 7500,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'DATA SCIENCE PREPARE (RUS)',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    }
  },
  ux: {
    online: {
      ru: {
        price: 275000,
        docUrl: CONTRACT_AGREEMENTS,
        productName: 'Образовательные услуги по курсу  UX/UI Design',
        terminalkey: TERMINALKEY.OOO,
        taxation: TAXATION.OOO
      }
    }
  }
}

export default courses
